import request from '@/utils/request';
export var defaultCampaignData = {
  id: 0,
  redeemedBy: 'user',
  userName: '',
  redeemedPoints: 0,
  campaignId: null,
  campaign: {
    id: null
  },
  userId: null,
  user: {
    id: null
  },
  shippingId: 0,
  shipping: {
    id: null
  }
};
export var getCampaignsRedeems = function getCampaignsRedeems(params) {
  return request({
    url: '/campaigns-redeems',
    method: 'get',
    params: params
  });
};
export var getCampaignsRedeemById = function getCampaignsRedeemById(id) {
  return request({
    url: "/campaigns-redeems/".concat(id),
    method: 'get'
  });
};
export var updateCampaignsRedeem = function updateCampaignsRedeem(id, data) {
  return request({
    url: "/campaigns-redeems/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteCampaignsRedeem = function deleteCampaignsRedeem(id) {
  return request({
    url: "/campaigns-redeems/".concat(id),
    method: 'delete'
  });
};
export var createCampaignsRedeem = function createCampaignsRedeem(data) {
  return request({
    url: '/campaigns-redeems/',
    method: 'post',
    data: data
  });
};